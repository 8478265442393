import TOOL from '../../assets/tool.png'
import NTBK from '../../assets/notebook.png'
import FLDR from '../../assets/folder.png'

const data = [
    {id: 1, icon: TOOL, title: 'Exprience', desc: '5+ Years Working'}, 
    {id: 2, icon: FLDR, title: 'Bachelor', desc: 'of Software Engineering'}, 
    {id: 3, icon: NTBK, title: 'Projects', desc: '10+ Completed'}, 
]

export default data;

const titles = {
    h5: 'Get to Know',
    h2: 'About Me',
    title: 'Constantly growing as a professional in web development and other related IT industries.',
    subtitle: 'My engineering experience include application development with microservice architecture, and my background in leading instilled a strong foundation in excellent interpersonal, written, and verbal communication skills. I approach application development with passion, care, and diligent attention to detail. I look forward to each challenge as an opportunity to build on a lifelong pursuit of learning.',
    button: "Download CV "
};

export { titles };