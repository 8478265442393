import IMG5 from '../../assets/flx.png'
import IMG6 from '../../assets/str.png'
import IMG7 from '../../assets/fire-dynamic-color.png'
import IMG8 from '../../assets/target-dynamic-color.png'
import IMG9 from '../../assets/web.jpg'
import IMG10 from '../../assets/at.png'
import {FaTools} from 'react-icons/fa'
import {BsBriefcaseFill} from 'react-icons/bs'

const data = [
    {
        id: 1, 
        anim: "fade-up",
        img: IMG9, 
        link: 'https://www.orisha.com/',
        title: 'Full-Stack Engineer at Orisha · 1.5 yrs', 
        focus: 'POS, ERP, WMS', 
        tech:' react.js · redux · javascript · typescript · java · postgreSQL · webSQL · css prepocessors · css · cypress · jest', 
        pitchimg: IMG7,
        pitch: "Collaborated within a team to develop React-based Point of Sale/Service system. The system has gained widespread adoption, being utilized by renowned brands such as Nike, Decathlon, etc.",
        projects: true,
        icon: <FaTools/>,
        d1: 'Cash Management: engineered and implemented cash management module, streamlining the handling of cash transactions within the POS system.', 
        d2: 'Discount engine: developed and integrated generic discounts module to implement all types of promotions in the React-based POS',
        d3: 'Multicurrency Payments: designed and integrated a multicurrency payment system to facilitate transactions for international clients.', 
    }, 

    {
        id: 2, 
        anim: "fade-left",
        img: IMG6,
        title: 'Technical Project Manager / Product Owner at NDA · 2 yrs', 
        focus: 'CRM , CMS , ML', 
        tech: 'jira · python · tableau · grafana · postgreSQL · react.js · javascript · prometheus · new relic · jenkins · cloudflare · elasticsearch', 
        pitchimg: IMG8,
        pitch: 'Led a team across multiple departments including backend, machine learning, frontend, DevOps, and QA, through the entire product lifecycle, ensuring alignment with business goals and customer needs.',
        icon: <BsBriefcaseFill/>,
        d1: 'Developed and maintained product vision, strategy, and roadmaps, translating business objectives into technical requirements.', 
        d2: 'Defined and prioritized the product backlog, gathering and analyzing customer feedback and market research to guide decisions.',
        d3: 'Implemented strategies to increase revenue and brand growth, managing budgets up to $2M annually.',
    }, 

    {
        id: 3, 
        anim: "fade-right",
        img: IMG5, 
        link: 'https://flex.com/',
        title: 'Software Developer at Flex · 2 yrs', 
        focus: 'ERP , WMS , SRM , EDI', 
        tech:' react.js · javascript · html · css · python · urwid · postgreSQL · java · aioHTTP · rocketU2 · kubernetes · jenkins', 
        pitchimg: IMG10,
        pitch: "Collaborated within a team to develop business management software for internal use in the company's factories and warehouses.",
        icon: <FaTools/>,
        d1: "Created an ETL and report generation application for factory-related processes to replace manual work with excel macro.",
        d2: "Created a CLI library using urwid to spin up CLI apps on different factory's embedded devices.",
        d3: "Created a modern and user-friendly web interface for displaying report data and business-related information.",
        d4: "Created a web application for warehouse inventory management to enhance overall user experience and add a dynamic touch, replacing the existing old-fashioned terminal interface.",
        d5: "Enhanced various existing coders and decoders for EDI signals used in communication between factory's customers.",
        d6: "Improved existing applications for data interchange with customer APIs.",
    }, 

]

export default data;

const titles = {
    title: 'Experience',
    subtitle: "Learn more about my Commercial Practice in this division.",
    tech: "Technologies  :  ",
    focus: "Focus on  :  ",
    projects: "Projects  :  "
};

export { titles };